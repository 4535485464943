
@import "../../bootstrap/variables.less";

@body-bg:					#000;

@text-color:				#ff0;
@link-color:				#fff;
@link-hover-color:			#fff;
@link-hover-decoration:		underline;

@font-family-base:			'Arial black';
@font-size-base:			10px;
@line-height-base:			1.25;
@font-size-h1:				36px;
@headings-small-color:		#fff;

@pager-padding:					2px;
@pager-border:					none;
@pager-text-decoration:			underline;
@pager-color:					#fff;
@pager-color-active:			#ffff00;
@pager-font-weight-active:		normal;
@pager-text-decoration-active:	none;
@pager-hover-bg:				transparent;
@pager-font-size:				24px;
@pager-font-size-mobile:		24px;

@thumb-width:				490px;
@thumb-height:				368px;
@thumb-margin-top:			0;
@thumb-margin-right:		0;
@thumb-margin-bottom:		0;
@thumb-margin-left:			0;
@thumb-border-width:		2px;
@thumb-border:				@thumb-border-width solid #FFCC00;
@thumb-hover-border:		@thumb-border-width solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				20px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				25px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	0 0 10px;
@sites-footer-margin:				2px;
@sites-footer-size:					12px;
@sites-footer-color:				@text-color;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";
@import "common/pagination.less";

body {
	padding-top: 40px;
}
a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	font-weight: bold;
	text-align: center;
	margin: 0 auto 40px;
	padding: 2px;
	max-width: 940px;
	.address {
		color: #fff;
		font-weight: normal;
	}
}
h2 {
	font-size: 12px;
	max-width: 940px;
	margin: 0 auto 10px;
	text-align: center;
}
.links {
	font-size: 18px;
	a {
		color: #fc0;
	}
	.nolink {
		color: #ff0;
		text-decoration: none;
	}
	.week {
		color: #fff;
	}
}
#ad-footer {
	img,
	video {
		border: 1px solid #ffff00;
	}
}